<template>
  <div id="approach">
    <page-content
      class="approach-page-header pb-50px"
      title="Our Approach"
      subtitle="Key factors for successfull solution delivery are the team, methodology, technology and strong partnership with the customer."
    />
    <section id="section-our-approach" class="pt-0">
      <div class="nstra-container">
        <div class="section-body">
          <ul class="timeline">
            <li class="timeline-line"></li>
            <li class="timeline-item">
              <div class="timeline-dot">
                <span></span>
              </div>
              <b-row>
                <b-col md="6" class="timeline-side" v-scrollanimation="'left'">
                  <div class="timeline-inner">
                    <h5 class="timeline-number">01</h5>
                    <img src="@/assets/images/our-approach/our-approach-idea.jpeg" alt="idea image" class="timeline-img" />
                  </div>
                </b-col>
                <b-col md="6" class="timeline-info" v-scrollanimation="'right'">
                  <div class="timeline-inner">
                    <h5 class="timeline-title">Idea</h5>
                    <p class="timeline-text">
                      It starts with your idea that might come from the needs for business improvement or your dream to build a great digital product.
                      <br />
                      <br />
                      We are more than happy to listen and get the whole story of your idea. We also tell the story about ourselves and introduce our services that will make sure your idea becomes one
                      great digital product.
                    </p>
                  </div>
                </b-col>
              </b-row>
            </li>
            <li class="timeline-item">
              <div class="timeline-dot">
                <span></span>
              </div>
              <b-row>
                <b-col md="6" class="timeline-side" v-scrollanimation="'right'">
                  <div class="timeline-inner">
                    <h5 class="timeline-number">02</h5>
                    <img src="@/assets/images/our-approach/our-approach-discovery.jpeg" alt="discovery image" class="timeline-img" />
                  </div>
                </b-col>
                <b-col md="6" class="timeline-info" v-scrollanimation="'left'">
                  <div class="timeline-inner">
                    <h5 class="timeline-title">Discovery</h5>
                    <p class="timeline-text">
                      The most essential part of the whole journey is finding out more and in detail about your idea and every part that supports it, such as the business, people, infrastructure and
                      culture.
                      <br />
                      <br />
                      Our Product Manager and Technical Leader will analyze and define the holistic strategy which will become the product development roadmap. We will then propose it to you, starting
                      with the MVP design and financial offering for the resources needed to accomplish your goal.
                    </p>
                  </div>
                </b-col>
              </b-row>
            </li>
            <li class="timeline-item">
              <div class="timeline-dot">
                <span></span>
              </div>
              <b-row>
                <b-col md="6" class="timeline-side" v-scrollanimation="'left'">
                  <div class="timeline-inner">
                    <h5 class="timeline-number">03</h5>
                    <img src="@/assets/images/our-approach/our-approach-team.jpeg" alt="team image" class="timeline-img" />
                  </div>
                </b-col>
                <b-col md="6" class="timeline-info" v-scrollanimation="'right'">
                  <div class="timeline-inner">
                    <h5 class="timeline-title">Team</h5>
                    <p class="timeline-text">
                      Our dedicated team ensures the smoothness of development and delivery process. Implementing Agile Scrum Methodology to ensure transparency, adaptive planning, rapid delivery,
                      continuous development and improvement always in check.
                    </p>
                    <div class="slider-team mt-15px">
                      <vue-slick-carousel v-bind="settings">
                        <div
                          class="slider-item"
                          v-match-heights="{
                            el: ['.slider-team-inner'],
                          }"
                        >
                          <div class="slider-inner slider-team-inner">
                            <h5 class="slider-title">
                              Product Manager
                            </h5>
                            <p class="slider-text">
                              Responsible for the full process of the application development lifecycle.
                            </p>
                          </div>
                        </div>
                        <div class="slider-item">
                          <div class="slider-inner slider-team-inner">
                            <h5 class="slider-title">
                              Technical Leader
                            </h5>
                            <p class="slider-text">
                              Responsible for the architecture design, technology selection and the technical aspect of the engineering team.
                            </p>
                          </div>
                        </div>
                        <div class="slider-item">
                          <div class="slider-inner slider-team-inner">
                            <h5 class="slider-title">
                              Engineer
                            </h5>
                            <p class="slider-text">
                              Responsible for the development process based on assigned role and task.
                            </p>
                          </div>
                        </div>
                        <div class="slider-item">
                          <div class="slider-inner slider-team-inner">
                            <h5 class="slider-title">
                              Tester (Quality Assurance)
                            </h5>
                            <p class="slider-text">
                              Responsible for all stages of testing process by developing and running test scenarios ensuring the quality of the delivery
                            </p>
                          </div>
                        </div>
                        <div class="slider-item">
                          <div class="slider-inner slider-team-inner">
                            <h5 class="slider-title">
                              DevOps
                            </h5>
                            <p class="slider-text">
                              Responsible for the environment configuration, CI/CD, security, reliability and scalability of the infrastructure.
                            </p>
                          </div>
                        </div>
                        <div class="slider-item">
                          <div class="slider-inner slider-team-inner">
                            <h5 class="slider-title">
                              Technical Writer
                            </h5>
                            <p class="slider-text">
                              Responsible for functional and technical documentation of the product.
                            </p>
                          </div>
                        </div>
                      </vue-slick-carousel>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </li>
            <li class="timeline-item">
              <div class="timeline-dot">
                <span></span>
              </div>
              <b-row>
                <b-col md="6" class="timeline-side" v-scrollanimation="'right'">
                  <div class="timeline-inner">
                    <h5 class="timeline-number">04</h5>
                    <img src="@/assets/images/our-approach/our-approach-storyboard.jpeg" alt="storyboard image" class="timeline-img" />
                  </div>
                </b-col>
                <b-col md="6" class="timeline-info" v-scrollanimation="'left'">
                  <div class="timeline-inner">
                    <h5 class="timeline-title">Storyboard</h5>
                    <p class="timeline-text">
                      The product manager presents to you the user stories and user interface/interaction design in the shape of a storyboard where you can have a better understanding of how the
                      product looks and interact with the user.
                    </p>
                  </div>
                </b-col>
              </b-row>
            </li>
            <li class="timeline-item">
              <div class="timeline-dot">
                <span></span>
              </div>
              <b-row>
                <b-col md="6" class="timeline-side" v-scrollanimation="'left'">
                  <div class="timeline-inner">
                    <h5 class="timeline-number">05</h5>
                    <img src="@/assets/images/our-approach/our-approach-development.jpeg" alt="development image" class="timeline-img" />
                  </div>
                </b-col>
                <b-col md="6" class="timeline-info" v-scrollanimation="'right'">
                  <div class="timeline-inner">
                    <h5 class="timeline-title">Development</h5>
                    <p class="timeline-text">
                      Once the roadmap and storyboard are approved, the team will work together closely with you and your team to embark on the exciting journey of the development process. We
                      implement Agile Methodology (Scrum) to manage the whole development lifecycle.
                    </p>
                  </div>
                </b-col>
              </b-row>
            </li>
            <li class="timeline-item">
              <div class="timeline-dot">
                <span></span>
              </div>
              <b-row>
                <b-col md="6" class="timeline-side" v-scrollanimation="'right'">
                  <div class="timeline-inner">
                    <h5 class="timeline-number">06</h5>
                    <img src="@/assets/images/our-approach/our-approach-launch.jpeg" alt="launch image" class="timeline-img" />
                  </div>
                </b-col>
                <b-col md="6" class="timeline-info" v-scrollanimation="'left'">
                  <div class="timeline-inner">
                    <h5 class="timeline-title">Launch</h5>
                    <p class="timeline-text">
                      Once all features of your product is ready, we’ll be there to launch it together and we will provide transfer knowledge to your team or provide you any necessary support needed
                      to ensure the livelihood of your product
                    </p>
                  </div>
                </b-col>
              </b-row>
            </li>
          </ul>
          <div class="text-center">
            <img src="@/assets/images/scrum.png" alt="Scrum Methodology" class="w-100 mt-60px scrum-img" v-scrollanimation="'up'" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Vue from "vue";
import VueSlickCarousel from "vue-slick-carousel";
import VueMatchHeights from "vue-match-heights";
Vue.use(VueMatchHeights);

import { BRow, BCol } from "bootstrap-vue";

const PageContent = () => import("@/components/PageContent.vue");

export default {
  name: "Services",
  components: {
    BRow,
    BCol,
    VueSlickCarousel,
    PageContent,
  },
  data: () => ({
    settings: {
      autoplay: true,
      arrows: false,
      dots: true,
      dotsClass: "slick-dots slider-dots-custom",
      edgeFriction: 0.35,
      infinite: false,
      speed: 1000,
      slidesToShow: 2,
      slidesToScroll: 2,
      focusOnSelect: true,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    },
  }),
};
</script>
